import React from 'react';

const StatsBox = ({ primaryText, secondaryText }) => (
  <div className="py-3 md:py-0">
    <p className="m-8 text-2xl lg:text-4xl font-semibold text-center text-primary">{primaryText}</p>
    <p className="font-semibold mb-6 text-justify">{secondaryText}</p>
  </div>
);

export default StatsBox;
