import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HomeImage from '../svg/HomeImage';
import Kids from '../svg/Kids';
import SvgCharts from '../svg/SvgCharts';
import LogoPenPaper from '../svg/LogoPenPaper';
import LogoOpportunity from '../svg/LogoOportunidad';
import LogoSolucion from '../svg/LogoSolucion';

const Aplicacion = () => {
  return (
    <Layout>
      <section id="index" className="pt-5 md:pt-20">
        <div className="container mx-auto px-5 mt-8">
          <div className="md:flex mx-auto">
            <div className="flex-1 flex-center">
              <h2 className="text-3xl lg:text-5xl font-semibold text-center">Tu solución a pruebas de Fonoaudiología</h2>
              <p className="text-xl text-center lg:text-xl font-light"></p>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-5 md:px-0 text-center">
          <div className="flex flex-col sm:flex-row mt-16 lg:px-8 justify-around">
            <div className=" container py-5 sm:w-1/4">
              <LogoPenPaper />
              <StatsBox
                primaryText="Contexto"
                secondaryText="Los fonoaudiólogos hacen pruebas dirigidas y repetitivas, que deben grabar en sus teléfonos móviles, para después transcribir a mano con lápiz y papel. Una de ellas es el Test de Articulación a la Repetición (TAR), prueba que permite evaluar el nivel fonético de los niños, pudiendo así detectar posibles dislalias o inconsistencia fonémica, así como también evaluar la memoria auditiva."
              />
            </div>
            <div className="w-auto py-5 sm:w-1/4">
              <LogoOpportunity />
              <StatsBox
                primaryText="Oportunidad"
                secondaryText="Integrar tecnología de aplicaciones móviles y redes neuronales para transcribir una pronunciación literal a partir de audios y responder a necesidades que, hasta el día de hoy, no estaban cubiertas."
              />
            </div>
            <div className="w-auto py-5 sm:w-1/4">
              <LogoSolucion />
              <StatsBox
                primaryText="Solución"
                secondaryText="EufoniApp se convierte en una herramienta de apoyo al trabajo diario de los fonoaudiólogos al automatizar el TAR, añadiéndole variadas funcionalidades, como la personalización de la prueba misma, ejercicios complementarios, seguimiento del avance de los beneficiarios a través de una ficha de paciente integrada, entre otros."
              />
            </div>
          </div>
        </div>

        <section id="Objetivos" className="py-5 lg:pb-40 lg:pt-48">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Beneficios</h2>

            <SplitSection
              primarySlot={
                <HomeImage />
              }
              secondarySlot={ 
                <div className="items-center ">
                <h3 className="text-3xl font-semibold leading-tight text-blue-700">
                  Para fonoaudiólogos y fonoaudiólogas
                </h3>
                <ul>
                  <li>
                    <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                      Ahorrar tiempo
                    </p>
                  </li>
                  <li>
                    <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                      Concentrarse en su paciente y no en llenar documentos
                    </p>
                  </li>
                  <li>
                    <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                      Acceso rápido a la ficha de sus pacientes
                    </p>
                  </li>
                  <li>
                    <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                      Seguimiento de progreso 
                    </p>
                  </li>
                </ul>
              </div>
              }
            />


            <SplitSection
              primarySlot={
                <div className=" items-center">
                  <h3 className="text-3xl font-semibold leading-tight text-blue-700">
                    Para niños y niñas evaluadas
                  </h3>
                  <ul>
                    <li>
                      <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                        Experiencia innovadora
                      </p>
                    </li>
                    <li>
                      <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                        Ejercicios integrados que consideran su avance
                      </p>
                    </li>
                    <li>
                      <p className="font-medium mt-8 text-xl font-light leading-relaxed">
                        Pruebas que se adecúan a sus necesidades
                      </p>
                    </li>
                  </ul>
                </div>
              }
              secondarySlot={ <Kids/>}
            />

          </div>
        </section>
      </section>
    </Layout>
  );
};
export default Aplicacion;
